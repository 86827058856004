<template>
  <v-footer :dark="isDarkTheme" color="transparent" elevation="0" class="pt-16 pb-8">
    <v-container>
      <v-row justify="center" no-gutters class="flex-column align-center">
        <div class="d-print-none text-center">
          <v-btn
            v-for="link in links"
            :key="link.to"
            :to="{ name: link.to, exact: true }"
            text
            plain
            class="my-2"
          >
            {{ link.title }}
          </v-btn>
        </div>

        <v-col class="text-center mx-auto col-12 col-lg-8">
          <v-divider class="my-4"></v-divider>
          <p>© {{ currentYear }} PRIA Technologies, LLC. All rights reserved.</p>
          <p v-if="showDisclosure" class="body-2">
            The Social Security Risk Score is an independent, non-partisan diagnostic tool
            that is not connected with, affiliated with, or specifically endorsed by the
            United States government or the Social Security Administration. Licensed
            financial advisors, accountants, attorneys, and insurance agents who utilize
            the Social Security Risk Score may offer other products or services and may be
            compensated by commissions and/or fees for any other services they may
            provide.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  props: {
    isDarkTheme: {
      type: Boolean,
      default: false,
    },
    showDisclosure: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      links: [
        {
          title: "Privacy Policy",
          to: "PrivacyPolicy",
        },
        {
          title: "Security Statement",
          to: "SecurityStatement",
        },
        {
          title: "Methodology",
          to: "Methodology",
        },
      ],
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped></style>
